<script setup lang="ts">
import { type HTMLAttributes, computed, useAttrs } from "vue";
import {
    PopoverContent,
    type PopoverContentEmits,
    type PopoverContentProps,
    PopoverPortal,
    useForwardPropsEmits,
} from "radix-vue";
import { installAntlerComponent } from "@/Utils/component";

defineOptions({
    inheritAttrs: false,
});

const props = withDefaults(
    defineProps<PopoverContentProps & { class?: HTMLAttributes["class"] }>(),
    {
        align: "center",
        sideOffset: 4,
    },
);
const emits = defineEmits<PopoverContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;

    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
const attrs = useAttrs();

const classes = {
    base: "z-50 w-[320px] max-w-screen rounded-md border bg-white p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
};
const { aClass } = installAntlerComponent(
    "popover-content",
    {},
    classes,
    attrs,
);
</script>

<template>
    <PopoverPortal>
        <PopoverContent
            v-bind="{ ...forwarded, ...$attrs }"
            :class="aClass()"
        >
            <slot />
        </PopoverContent>
    </PopoverPortal>
</template>
